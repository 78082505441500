.searchBar {
	height: 100px;
	width: calc(100% - 80px);
	margin-left: 80px;
	border-bottom: 1px solid rgba(27, 40, 49, 0.23);
	position: fixed;
	top: 0;
	background-color: var(--white);
	z-index: 59;
}

.my-favorites-btn {
	margin-right: 1rem;
}

@media (min-width: 1024px) {
	.searchBar {
		margin-left: 0;
		width: 100%;
		padding-left: 300px;
		left: 0;
	}

	.my-favorites-btn {
		margin-left: 3rem;
	}
}
@media (min-width: 2200px) {
	.searchBar {
		padding-left: 0;
	}
}

.searchBarContainer {
	max-width: 1600px;
	padding: 0 1.5rem 0 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: flex-end;
	margin: 0 auto;
	gap: 10px;
}

.iaSearch {
  display: flex;
  width: 100%;
  padding: 5px 5px 5px 15px;
  border-radius: 35px;
  border: 0.5px solid rgba(27, 40, 49, 0.47);
  background: var(--White, #fff);
  align-items: flex-end;
}

@media (min-width: 768px) {
  .iaSearch {
    padding: 8px 15px;
  }
}

.iaSearchBar {
  width: 100%;
  font-size: 17px;
  border: none;
  min-height: 38px;
  max-height: 300px;
  outline: none;
  resize: none;
  display: flex;
  align-items: center;
}

.iaSearchButton {
  font-size: 20px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(27, 40, 49, 0.1);
  color: #1b2831;
  transition: 0.3s;
}

.withText {
  background: rgba(0, 188, 146, 0.16);
  color: var(--green-aquinas);
}

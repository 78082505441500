.Tutorials {
	margin-top: 100px;
	min-height: calc(100vh - 100px);
	padding: 40px 0;
	background-color: #f0f0f0;
}

.banner-tutorial-section {
	background: white;
	border-radius: 5px;
	margin-bottom: 2rem;
}

.content-banner-tutorial {
	padding: 2rem 1rem;
}

.title-banner-tutorial {
	font-size: 24px;
}

.description-banner-tutorial {
	padding-top: 1rem;
}

.helper-banner-tutorial {
	font-weight: 700;
	color: var(--green-aquinas);
	padding-top: 1rem;
}

@media (min-width: 720px) {
	.videos-banner-tutorial {
		display: flex;
		padding-bottom: 1rem;
	}
}
@media (min-width: 1024px) {
	.Tutorials {
		margin-left: 300px;
	}
}

@media (min-width: 1440px) {
	.banner-tutorial-section {
		display: flex;
		align-items: center;
	}
	.content-banner-tutorial {
		padding: 2rem;
	}
	.videos-banner-tutorial {
		padding: 1.5rem;
	}
}

.TutorialsContainer {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 30px;
	row-gap: 40px;
	padding-bottom: 40px;
	min-height: 420px;
}

@media (min-width: 768px) {
	.TutorialsContainer {
		grid-template-columns: repeat(2, 1fr);
	}
}
@media (min-width: 1600px) {
	.TutorialsContainer {
		grid-template-columns: repeat(3, 1fr);
	}
}

.loginHomeModal {
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loginHomeModal .modal-content {
  overflow-y: auto;
  max-width: 1340px;
  max-height: 90vh;
  padding: 20px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .loginHomeModal .modal-content {
    padding: 0;
  }
}

.modal-content .textContent {
  /* overflow-y: auto; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}

@media (min-width: 768px) {
  .modal-content .textContent {
    max-width: 650px;
    padding: 20px 0px 20px 20px;
  }
}

.textContent h2 {
  margin-bottom: 10px;
}

.modalResources {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalResources div {
  display: flex;
  gap: 15px;
}

.modalResources div img {
  width: 25px;
}

.modal-content .imageContent {
  display: none;
}

@media (min-width: 768px) {
  .modal-content .imageContent {
    display: block;
    max-width: 600px;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}

.gradiente {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0) 20%
  );
}

.imageContent img {
  width: auto;
  height: 100%;
  max-height: 810px;
}

/* CLOSE BUTTON */

.modal-content .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  cursor: pointer;
  font-size: 30px;
}

@media (min-width: 768px) {
  .modal-content .closeButton {
    top: 25px;
    right: 35px;
  }
}

.loginButtonModalContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 0
}

.loginButtonModal {
  height: 44px;
  border-radius: 5px;
  padding: 0 10px;
  background-color: var(--green-aquinas);
  color: var(--green-aquinas);
  font-weight: 600;
  font-size: 15px;
  transition: 0.3s;
  color: var(--white);
}

.loginButtonModal:hover {
  color: var(--white);
  background-color: #00bc93de;
}

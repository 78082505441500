.cardNewsSearched {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(4, 1fr);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	background-color: white;
	border-radius: 5px;
	padding: 15px;
	gap: 20px;
	max-height: 230px;
	position: relative;
}

.cardNewsSearched .containerImage {
	position: relative;
	overflow: hidden;
	width: 100px;
	height: 100px;
	transition: transform 0.3s ease;
}

.cardNewsSearched .info {
	display: none;
	width: 65%;
}

@media (min-width: 650px) {
	.cardNewsSearched .containerImage {
		width: 200px;
		height: 200px;
	}
}

.cardNewsSearched .containerImage img {
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: transform 0.3s ease;
}

.containerImage:hover img {
	transform: translate(-50%, -50%) scale(1.1);
}

.cardNewsSearchedContainer {
	grid-column: 2 / 5;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.cardNewsSearched .info {
	display: none;
}

@media (min-width: 650px) {
	.cardNewsSearched .info {
		display: flex;
		gap: 10px;
	}
}

.titleNewsSearched {
	padding-right: 4rem;
	height: 25px;
	color: #252525;
	font-size: 20px;
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

@media (min-width: 650px) {
	.titleNewsSearched {
		height: 48px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

.publicationDateNewsSearched {
	padding: 0 5px;
	border-radius: 5px;
	background-color: var(--light-grey);
	color: #252525;
	font-weight: 500;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 30px;
	height: 30px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.sourceNewsSearched {
	font-weight: 600;
	text-transform: uppercase;
	padding: 0 5px;
	border-radius: 5px;
	background-color: var(--olib-blue);
	color: var(--white);
	font-weight: 600;
	font-size: 13px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	line-height: 30px;
	height: 30px;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	/* max-width: 170px; */
}

.descriptionNewsSearched {
	height: 48px;
	font-size: 15px;
	font-weight: 400;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

@media (min-width: 650px) {
	.descriptionNewsSearched {
		height: 70px;

		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}

.showMore {
	text-align: start;
	text-decoration: underline;
	color: #00bc92;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
}

.thesaurus-main {
  background: #e9e9e9;
  min-height: calc(100vh - 100px);
  margin-top: 100px;
}

@media (min-width: 1024px) {
  .thesaurus-main {
    margin-left: 300px;
  }
}
.btnSearch {
  background-color: #00bc92;
  border: 1px solid #00bc92;
  border-radius: 0px 6px 6px 0px;
  font-weight: 600;
  color: white;
  padding: 0 10px;
}
.btnSearch:hover {
  background-color: #04ad89;
  color: white;
}

/* main */

.thesaurus {
  min-height: 40vh;
}
@media (min-width: 760px) {
  .thesaurus {
    display: flex;
    min-height: 60vh;
  }
}

/* thesaurus Navigation */

.thesaurusNavigation {
  width: 100%;
  padding: 20px 0;
  background-color: #e9e9e9;
}

@media (min-width: 760px) {
  .thesaurusNavigation {
    width: 20%;
    min-width: 150px;
    padding: 60px 0;
  }
}
.thesaurusNavigation__title {
  font-size: 16px;
}
.thesaurusNavigationContainer {
  width: 80%;
  margin: 0 auto;
}
.thesaurusNavigation__categories {
  margin-top: 10px;
  padding-left: 16px;
  border-left: 5px solid #00bc92;
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (min-width: 760px) {
  .thesaurusNavigation__categories {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
}
.thesaurusNavigation__categories a {
  color: #252525;
}
/* .contentCategory {
  text-decoration: underline;
} */

/* thesaurus Results */

.thesaurusResults {
  padding: 60px 0;
  background-color: #f3f3f3;
}
@media (min-width: 769px) {
  .thesaurusResults {
    width: 80%;
  }
}
.thesaurusResultsContainer {
  width: 80%;
  margin: 0 auto;
}
.searchWord {
  color: #252525;
  font-size: 52px;
  font-weight: 700;
}
.wordType {
  font-size: 24px;
  font-weight: 400;
}
.titleSectionResult {
  margin: 40px 0 10px;
  font-size: 24px;
  font-weight: 600;
}
.definition {
  list-style: decimal;
  margin-left: 20px;
}
.definition::marker {
  font-weight: 600;
}
.textSectionResult {
  font-size: 16px;
  text-align: justify;
}
.entriesNearContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.entrieNearButton {
  background-color: #00bc92;
  padding: 3px 10px;
  border-radius: 8px;
}
.entrieNearButton p {
  color: white;
}
.noDataContainerThesaurus {
  min-height: calc(100vh - 100px);
  margin-top: 100px;
  font-size: 28px;
  color: #25252588;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1024px) {
  .noDataContainerThesaurus {
    margin-left: 300px;
  }
}
.noDataContainerThesaurus p {
  font-size: 14px;
}

.loaderThesaurus {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #00bc92;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotationThesaurus 1s linear infinite;
}

@keyframes rotationThesaurus {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noDataFound {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.VideoLibrary {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  padding: 40px 0;
  background-color: #f0f0f0;
}

@media (min-width: 1024px) {
  .VideoLibrary {
    margin-left: 300px;
  }
}

.VideoLibraryContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  row-gap: 40px;
  padding-bottom: 40px;
  min-height: 420px;
}

@media (min-width: 768px) {
  .VideoLibraryContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1600px) {
  .VideoLibraryContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

.VideoLibrarySummarySearch {
  margin-bottom: 30px;
  margin-top: 0px;
}

.VideoLibrarySummarySearch button {
  font-size: 28px;
  display: flex;
  align-items: flex-start;
}
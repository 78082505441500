.navigationPages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 30px;
}

.navigationPages div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.navigationPages div p {
  font-size: 14px;
}

.navigationPagesButton {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #dadada;
  background-color: var(--green-aquinas);
  user-select: none;
  color: var(--white);
}

.navigationPagesButton.left {
  padding-right: 10px;
}
.navigationPagesButton.right {
  padding-left: 10px;
}

.navigationPagesButton.deactivateButton {
  background-color: var(--light-grey);
  cursor: auto;
}
.arrowButtom {
  font-size: 30px;
}

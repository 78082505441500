.fav-component {
	position: absolute;
	/* right: 1.5rem;
	top: 1.5rem; */
}

.no-absolute {
	position: relative;
	top: 0;
	right: 0;
}

.favorite-tooltip-detail {
	position: absolute;
	right: 0.3rem;
}

.favorite-tooltip__btns {
	position: absolute;
	background: white;
	padding: 0.3rem;
	border-radius: 0 7px 7px 7px;
	width: 120px;
	z-index: 2;
	border: 1px solid #707070;
	right: 0;
}

.favorite-tooltip__btns_btn {
	display: flex;
	align-items: center;
	color: #5c5b5b;
	cursor: pointer;
	width: 100%;
	padding: 0.3rem 0.2rem;
}

.favorite-tooltip__btns_btn img {
	filter: brightness(0) saturate(100%) invert(0);
}

.favorite-tooltip__btns_btn:hover {
	color: var(--green-aquinas);
	background-color: #00bc9326;
}
.favorite-tooltip__btns_btn:hover img {
	filter: none;
}

.favorite-tooltip__btns_btn img {
	width: 14px;
	margin-right: 0.3rem;
}

.favorite-tooltip__btns_btn .copy-icon {
	width: 18px;
}

.favorites-main {
	background: #e9e9e9;
	min-height: calc(100vh - 100px);
	margin-top: 100px;
}

.favorites-main .back-btn {
	width: fit-content;
}

.favorites-container_title {
	font-size: 25px;
	font-weight: 600;
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.favorite-list {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: center;
}

/* main */
.favorites-container {
	min-height: 40vh;
	padding-top: 2.5rem;
	padding-bottom: 4rem;
}

.favorite-detail-grid {
	display: flex;
	flex-direction: column;
}

.favorite-grid-4-column {
	grid-template-columns: repeat(4, 1fr);
	gap: 1.875rem;
}

.favorite-grid-3-column {
	grid-template-columns: repeat(3, 1fr);
	gap: 1.875rem;
}

.favorite-grid-2-column {
	grid-template-columns: repeat(2, 1fr);
	gap: 1.875rem;
}

@media (min-width: 760px) {
	.favorites-container {
		/* display: flex; */
		min-height: 60vh;
	}

	.favorite-list {
		justify-content: flex-start;
	}
}

@media (min-width: 1248px) {
	.favorite-detail-grid {
		display: grid;
	}
	.favorite-grid-3-column {
		grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
	}
	.favorites-main {
		margin-left: 300px;
	}
}

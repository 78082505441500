.favorites-main {
	background: #e9e9e9;
	min-height: calc(100vh - 100px);
	margin-top: 100px;
}

.favorites-container_title {
	font-size: 25px;
	font-weight: 600;
	margin-bottom: 2rem;
}
.favorite-list {
	display: flex;
	flex-wrap: wrap;
	gap: 1.875rem;
	justify-content: center;
}

.delete-favorite__title {
	text-align: center;
	font-size: 17px;
	font-weight: 600;
	color: var(--olib-blue);
}

.delete-favorite__buttons {
	padding-top: 1.5rem;
	display: flex;
	/* align-items: center; */
	justify-content: center;
	gap: 0 0.5rem;
}

.delete-favorite__yes {
	color: white;
	padding: 0.9rem;
	border-radius: 5px;
	background-color: var(--green-aquinas);
}
.delete-favorite__yes:hover {
	background-color: var(--green-aquinas-hover);
}

.close-modal__delete--image {
	height: 30px;
	width: 30px;
}

.close-modal__delete {
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
}

.delete-favorite__no {
	color: #8c8c8c;
	padding: 0.9rem;
	border-radius: 5px;
	background-color: white;
}

/* main */
.favorites-container {
	min-height: 40vh;
	padding-top: 2.5rem;
}

@media (min-width: 760px) {
	.favorites-container {
		/* display: flex; */
		min-height: 60vh;
	}

	.favorite-list {
		justify-content: flex-start;
	}
}

@media (min-width: 1024px) {
	.favorites-main {
		margin-left: 300px;
	}
}

.errorMessage {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

.sucessMessage {
  color: var(--green-aquinas);
  font-size: 14px;
  font-weight: 600;
}

.loginPageContainer .infoSectionContrainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

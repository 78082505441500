.sectionInstitutions .alice-carousel__prev-btn {
  position: absolute;
  top: 100px;
  background-color: var(--white);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  border: 0.3px solid rgba(27, 40, 49, 0.2);
  color: var(--blue-aquinas);
}
.sectionInstitutions .alice-carousel__prev-btn p:hover {
  color: black;
}
.sectionInstitutions .alice-carousel__next-btn {
  right: 0;
  position: absolute;
  top: 100px;
  background-color: var(--white);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  border: 0.3px solid rgba(27, 40, 49, 0.2);
}

.sectionInstitutions .alice-carousel__next-btn p:hover {
  color: black;
}

.sectionInstitutions .alice-carousel__stage-item {
  text-align: center;
}

.summarySearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding-top: 20px;
}

.summarySearchQuery {
  font-size: 20px;
  font-weight: 400;
}
.summarySearchTotal {
  font-size: 13px;
  font-weight: 400;
}

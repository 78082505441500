.interesting-links {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  background-color: #f0f0f0;
}

@media (min-width: 1024px) {
  .interesting-links {
    margin-left: 300px;
  }
}

@media (min-width: 1400px) {
  .interesting-links {
    margin-right: 370px;
  }
}

.interestingLinksResults {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  min-height: 560px;
  align-items: flex-start;
  padding: 20px 0 40px;
}

@media (min-width: 768px) {
  .interestingLinksResults {
    grid-template-columns: repeat(3, 1fr);
  }
}

.interestingLinksFiltersContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
}

.interestingLinksFiltersContainer
  .sectionFilterNews
  .filtersBarNews
  .filtersBarSectionNews
  .filtersBarSection__content.fixed {
  max-height: 500px;
}

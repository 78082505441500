.media-card-component {
	background: white;
	border-radius: 5px;
	padding: 1rem;
	cursor: pointer;
}

.media-card__image {
	/* width: 100%;
	height: 100%; */

	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.media-card__image .media-card__blurred-image {
	width: 100%;
	max-width: 230px;
	height: 100%;
	max-height: fit-content;
	border-radius: 5px;
	margin: 1rem 0;
	border-radius: 5px;
}
.media-card__title-up {
	display: flex;
	align-items: start;
	justify-content: center;
}

.media-card__title-up .fav-component {
	padding-top: 1.3rem !important;
}

.media-card__title {
	margin-top: 1rem;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 18px;
	font-weight: 600;
	height: 60px;
	width: 100%;
	padding-right: 3.5rem;
}
.media-card__description {
	-webkit-line-clamp: 3;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	word-break: break-all;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}

.media-card__time {
	position: absolute;
	left: 1rem;
	bottom: 1rem;
	z-index: 2;
	background: #00000054;
	border-radius: 5px;
	padding: 3px 12px;
	color: #fff;
}

.play-button__btn {
	position: absolute;
	z-index: 2;
	width: 70px;
	height: 59px;
}

.recoverPage {
  min-height: calc(100vh - 60px);
  background-color: #f0f0f0;
}

.recoverPageContainer {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 1rem;
}

@media (min-width: 768px) {
  .recoverPageContainer {
    gap: 40px;
  }
}

.backRecoverButton {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 600;
}

.recoverContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 28px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--white);
  border-radius: 5px;
  text-align: center;
}

@media (min-width: 600px) {
  .recoverContainer {
    padding: 40px;
  }
}

.recoverContainer .descriptive {
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
}

.recoverContainer .info {
  max-width: 400px;
  font-size: 25px;
  line-height: 28px;
}

.formRecover {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.formRecover__inputs {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.formRecover__inputs div {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 5px;
}

.formRecover__inputs .input-container {
  position: relative;
  font-size: 16px;
}

.input-container label {
  font-weight: 600;
}

.input-container input {
  width: 100%;
  padding-left: 30px;
  border: none;
  border-radius: 5px;
  height: 62px;
  font-size: 16px;
  background: #eaeeed;
}

.input-container svg {
  position: absolute;
  top: 52px;
  left: 8px;
  color: #888;
}

.btnLogin {
  background-color: var(--green-aquinas);
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
}

.recoverPage .aux-text {
  text-align: center;
  font-weight: 600;
  transition: 0.3s;
}

.recoverPage .aux-text:hover {
  color: var(--green-aquinas);
}

.recoverPageContainer .infoSectionContrainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

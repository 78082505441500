.App {
	background-color: #f0f0f0;
}

.add-zoom {
	transition: transform 0.1s; /* Animation */
}

.add-zoom:hover {
	transform: scale(
		1.05
	); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.add-zoom-x3:hover {
	transform: scale(
		1.3
	); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

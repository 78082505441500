.newsQueryContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.newsQueryResults {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 10px;
  min-height: 710px;
}

@media (min-width: 1400px) {
  .newsQueryResults {
    grid-template-columns: repeat(2, 1fr);
  }
}

.newsQueryResults.loading {
  padding: 20px 0;
}

.newsQueryContentInfo {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.newsQueryContentInfo .summarySearch{
  padding-top: 0;
}

.newsQueryContentInfo button {
  height: 28px;
  display: flex;
  align-items: flex-start;
}

.newsQueryContentIcon {
  color: var(--blue-aquinas);
}

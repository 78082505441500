/* Card */
.favorite-card {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border: 1px solid #e9e9e9;
	padding: 1rem;
	display: flex;
	align-items: center;
	width: 275px;
	position: relative;
	cursor: pointer;
}

.favorite-card__image {
	margin-right: 1rem;
}

.favorite-card__image img {
	border-radius: 10px;
	height: 60px;
	object-fit: cover;
	width: 60px;
}

.favorite-card__title {
	font-size: 18px;
	font-weight: 800;
	line-height: 1.2rem;
}

.favorite-card__articles {
	font-size: 13px;
	font-weight: 400;
	padding-top: 0.3rem;
}

.favorite-card__settings {
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}

.favorite-card__settings {
	pointer-events: pointer;
}

.iaInit {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.iaInitContainer {
  margin: 20px 0;
  flex: 1;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .iaInitContainer {
    max-height: 1000px;
  }
}

.iaContainerButtons {
  display: flex;
  justify-content: space-between;
}

.goBackButton {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.goBackButton p {
  display: none;
}

@media (min-width: 768px) {
  .goBackButton p {
    display: block;
  }
}

.goBackButton svg {
  font-size: 24px;
}

.iaContainerInfo {
  max-width: 910px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.iaContainerInfo__text {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.iaContainerInfo__text h1 {
  color: var(--blue-aquinas);
  font-size: 20px;
  font-weight: 600;
}
.iaContainerInfo__text p {
  color: var(--blue-aquinas);
  font-size: 16px;
  font-weight: 400;
}

.iaContainerInfo__cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .iaContainerInfo__cards {
    flex-direction: row;
  }
}

.iaCardInit {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  max-width: 360px;
  border: 0.3px solid rgba(27, 40, 49, 0.234);
}

.iaCardInit p {
  color: #252525;
  font-size: 15px;
  font-weight: 600;
}

.iaContainerSearch {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.iaContainerSearch p {
  text-align: center;
  color: #252525;
  font-size: 12px;
  font-weight: 400;
}
